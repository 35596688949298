<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <div class="is-align-items-center is-flex is-justify-content-space-between">
        <h2 class="ant-modal-title has-text-primary has-text-weight-bold is-size-4">
          {{ title }}
        </h2>
      </div>
    </div>

    <a-form-item class="column is-12" :label="$t('Property')" name="propertyId">
      <InputProperty v-model:value="form.propertyId" @change="validField('propertyId')" />
    </a-form-item>

    <a-form-item class="column is-12" :label="$t('Neppan Price')" name="neppanPrice">
      <a-input-number v-model:value="form.neppanPrice" :size="size" style="width: 100%;" />
    </a-form-item>

    <a-form-item class="column is-12" :label="$t('PIOP Price')" name="piopPrice">
      <a-input-number v-model:value="form.piopPrice" :size="size" style="width: 100%;" />
    </a-form-item>

    <a-form-item
      class="column is-12"
      :label="$t('Date')"
      name="date"
    >
      <a-date-picker
        v-model:value="form.date"
        :size="size"
        style="width: 100%"
      />
    </a-form-item>

    <a-form-item class="column is-12" :label="$t('Notes')" name="notes">
      <a-input v-model:value="form.notes" :size="size" style="width: 100%;" />
    </a-form-item>

    <div class="column is-12 m-t-4x">
      <div class="is-flex is-justify-content-space-between">
        <div style="min-width: 100px">
          <div v-if="value && value.id">
            <DeleteLockedDate :record="value" @delete="$emit('close')">
              <template #handler="{ show }">
                <a-button
                  :size="size"
                  type="danger"
                  @click="show"
                >
                  {{ $t('Delete') }}
                </a-button>
              </template>
            </DeleteLockedDate>
          </div>
        </div>
        <div>
          <a-button
            :disabled="loading"
            :size="size"
            class="button-secondary-outline m-r-3x"
            style="min-width: 100px"
            @click="resetFields"
          >
            {{ $t('Cancel') }}
          </a-button>
          <a-button
            :loading="isSubmitting"
            :size="size"
            class="m-r-3x"
            style="min-width: 100px"
            type="primary"
            @click="handleSubmit"
          >
            {{ $t('Save') }}
          </a-button>
        </div>
      </div>
    </div>
  </a-form>
</template>

<i18n>
{
  "en": {
    "Add Room Bottom": "Add Room Bottom",
    "Edit Room Bottom": "Edit Room Bottom",
    "Item Name": "Item Name",
    "Cost": "Cost",
    "Cost Unit": "Cost Unit",
    "Covers": "Covers",
    "Item name is required": "Item name is required",
    "Property is required": "Property is required",
    "Room is required": "Room is required",
    "Cost is required": "Cost is required",
    "Cost unit is required": "Cost unit is required",
    "Covers is required": "Covers is required",
    "price": "Price",
    "percentage": "Percentage",
    "per-day": "Per Day",
    "per-max-pax": "Per Max Pax"
  },
  "ja": {
    "Add Room Bottom": "ボトム料金を追加する",
    "Edit Room Bottom": "ボトム料金を編集する",
    "Item Name": "ボトム名",
    "Cost": "数値",
    "Cost Unit": "単位",
    "Covers": "条件",
    "Item name is required.": "ボトム名は必須です",
    "Property is required.": "物件は必須です",
    "Room is required.": "部屋必須はです",
    "Cost is required.": "金額必須です",
    "Cost unit is required.": "単位は必須項目です",
    "Covers is required.": "条件は必須です",
    "price": "価格",
    "percentage": "%",
    "per-day": "日ごと",
    "per-max-pax": "最大人数ごと"
  }
}
</i18n>

<script>
import InputProperty from '@/components/InputProperty';
import DeleteLockedDate from '@/views/revenue-management/locked-dates/components/DeleteLockedDate';

export default {
  name: 'LockedDateForm',
  components: {
    DeleteLockedDate,
    InputProperty,
  },
  props: {
    value: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      isSubmitting: false,
      form: {
        propertyId: undefined,
        date: undefined,
        neppanPrice: undefined,
        piopPrice: undefined,
        notes: undefined,
      },
      size: 'large',
    };
  },
  computed: {
    title() {
      return this.value && this.value.id
        ? this.$t('Edit Locked Date')
        : this.$t('Add Locked Date');
    },
    rules() {
      const rules = {
        propertyId: [
          {
            required: true,
            message: this.$t('Property is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        neppanPrice: [
          {
            required: true,
            type: 'number',
            message: this.$t('Neppan price is required.'),
            trigger: ['change', 'blur'],
          },
        ],
        piopPrice: [
          {
            required: true,
            type: 'number',
            message: this.$t('PIOP price is required.'),
            trigger: ['change', 'blur'],
          },
        ],
      };

      return rules;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv) {
          const keys = Object.keys(this.form);
          keys.forEach((k) => {
            if (k in nv) {
              this.form[k] = nv[k];
            }
          });
        }
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        this.isSubmitting = true;
        await this.$refs.form.validate();

        if (this.value && this.value.id) {
          await this.$store.dispatch('rms-locked-dates/update', {
            ...this.form,
            id: this.value.id,
          });
        } else {
          await this.$store.dispatch('rms-locked-dates/create', this.form);
        }

        this.isSubmitting = false;
        this.$emit('close');
      } catch (error) {
        this.isSubmitting = false;
      }
    },
    validField(f) {
      setTimeout(() => {
        this.$refs.form.validateFields([f]);
      }, 300);
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>
