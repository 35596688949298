<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Locked Dates') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mobi">
          <div class="column">
            <Filter @filter="fetchData" />
          </div>
          <div class="column is-narrow">
            <Modal ref="modal" :mask="true">
              <template #default="{ hide }">
                <Form
                  :value="selected"
                  @close="
                    () => {
                      hide();
                      fetchData();
                      selected = undefined;
                    }
                  "
                  @cancel="
                    () => {
                      hide();
                      selected = undefined;
                    }
                  "
                />
              </template>
              <template #handler="{ show }">
                <button
                  v-permission="['add-locked-date']"
                  class="ant-btn ant-btn-lg ant-btn-primary"
                  @click.prevent="show"
                >
                  {{ $t('Add Locked Date') }}
                </button>
              </template>
            </Modal>
          </div>
        </div>
        <a-table
          row-key="id"
          :columns="columns"
          :data-source="records"
          :custom-row="rowClicked"
          :loading="isFetching"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <template #neppanPrice="{ record }">
            {{ $filters.number(record.neppanPrice) || '-' }}
          </template>
          <template #piopPrice="{ record }">
            {{ $filters.number(record.piopPrice) || '-' }}
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Room Bottom": "Room Bottom",
    "Add Room Bottom": "Add Room Bottom",
    "Item Name": "Item Name",
    "Cost": "Cost",
    "Cost Unit": "Cost Unit",
    "Covers": "Covers",
    "price": "Price",
    "percentage": "Percentage",
    "per-day": "Per Day",
    "per-max-pax": "Per Max Pax"
  },
  "ja": {
    "Room Bottom": "ボトム料金",
    "Add Room Bottom": "ボトム料金を追加する",
    "Item Name": "ボトム名",
    "Cost": "数値",
    "Cost Unit": "単位",
    "Covers": "条件",
    "price": "価格",
    "percentage": "%",
    "per-day": "日ごと",
    "per-max-pax": "最大人数ごと"
  }
}
</i18n>

<script>
import Modal from '@/components/Modal';
import checkPermission from '@/utils/permission';
import pagination from '@/components/mixins/pagination';
import Filter from './components/Filter';
import Form from './components/Form';

export default {
  name: 'Users',
  components: {
    Filter,
    Modal,
    Form,
  },
  mixins: [pagination],
  data() {
    return {
      records: undefined,
      selected: undefined,
      isFetching: false,
      columns: [
        {
          title: this.$t('Date'),
          dataIndex: 'date',
        },
        {
          title: this.$t('Neppan Price'),
          dataIndex: 'neppanPrice',
          key: 'neppanPrice',
          slots: { customRender: 'neppanPrice' },
        },
        {
          title: this.$t('Piop Price'),
          dataIndex: 'piopPrice',
          key: 'piopPrice',
          slots: { customRender: 'piopPrice' },
        },
        {
          title: this.$t('Notes'),
          key: 'notes',
          dataIndex: 'notes',
        },
      ],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchData();
    },
    async fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');

      try {
        const { data, pagination } = await this.$store.dispatch(
          'rms-locked-dates/list',
          this.$route.query,
        );

        this.records = data;

        console.log(this.records);

        this.setPagination(pagination);
      } finally {
        this.$store.commit('HIDE_FULLSCREEN_LOADER');
      }
    },
    rowClicked(record) {
      return {
        onClick: () => {
          if (!checkPermission(['modify-locked-date'])) {
            return;
          }

          this.selected = record;
          this.$refs.modal.show();
        },
      };
    },
  },
};
</script>
